import { Button, Card, CardContent, InputAdornment, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";
import { saveUser, updateWatchlist } from "../../redux/reducers/userSlice";
import authServices from "../../services/authServices";
import userServices from "../../services/userServices";
import { loggout } from "../../utils/functions";
import FormField from "../../components/form/formField";
import TermsConditions from "../../components/modals/terms_conditions";
import '../../global.css';
import { useGoogleLogin } from "@react-oauth/google";
import GoogleIcon from '@mui/icons-material/Google';
import axios from "axios";

export default function Login({ open, handleClose = () => { }, openSign = false, setShowSignUp = () => { }, showTerms, setShowTerms = () => { } }) {
    const { distributorCode, rm } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [otpForm, setOtpForm] = useState(false);
    const [signUp, setSignUp] = useState(false);
    const [number, setNumber] = useState("");
    const [inviteCode, setInviteCode] = useState("");
    const [otp, setOtp] = useState("");
    const user = useSelector(state => state.user.data);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (openSign) {
            setSignUp(openSign);
        }
    }, [openSign])

    const handleSendOtp = async () => {
        const data = {
            mobileNumber: number,
            distributorCode: distributorCode || "",
            rm: rm || ""
        }
        const res = await authServices.sendOTP(data);
        if (res && res.success) {
            setOtpForm(true);
            enqueueSnackbar("OTP sent successfully", { variant: "success" })
        }
        else {
            if (res.data.message) {
                enqueueSnackbar(res.data.message, { variant: "error" })
            }
            else {
                enqueueSnackbar("Something went wrong!", { variant: "error" })
            }
        }
    }

    const handleVerifyOtp = async () => {
        const data = {
            mobileNumber: parseInt(number),
            otp: otp
        }
        const res = await authServices.verifyOTP(data);
        if (res && res.success && res?.data?.length > 0) {
            sessionStorage.setItem("access", res.token.access.token);
            sessionStorage.setItem("refresh", res.token.refresh.token);
            dispatch(saveUser(res.data[0]));
            await getMyWatchlist()
            if (res.data[0].conditionAccepted) {
                enqueueSnackbar("Logged in successfully", { variant: "success" })
                navigate(`/dashboard?show=${(res.data[0]?.status === "FIRST_TIME" || res.data[0]?.status === "REGISTERED" || res.data[0]?.status === "KYC_APPROVED")}`, { state: res.data[0]?.status });
            }
            else {
                setOtp(true);
                setIsModalOpen(true);
                onClose();
            }
        }
        else {
            if (res.data.message) {
                enqueueSnackbar("OTP is incorrect", { variant: "error" })
            }
            else {
                enqueueSnackbar("Something went wrong!", { variant: "error" })
            }
        }
    }

    const getMyWatchlist = async () => {
        const res = await userServices.getMyWatchlist();
        if (res && res.success && res.data?.length > 0) {
            console.log("updating");
            dispatch(updateWatchlist(res.data));
        }
    }

    const handleGoogleLogin = useGoogleLogin({
        scopes: 'profile email https://www.googleapis.com/auth/user.phonenumbers.read',
        onSuccess: async (codeResponse) => {
            const userInfo = await axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${codeResponse.access_token}` },
                })
                .then(async (googleResponse) => {
                    const data = {
                        email: googleResponse.data.email,
                        distributorCode: distributorCode
                    }
                    const res = await authServices.googleLogin(data);
                    if (res && res.success && res?.data?.length > 0) {
                        sessionStorage.setItem("access", res.token.access.token);
                        sessionStorage.setItem("refresh", res.token.refresh.token);
                        dispatch(saveUser(res.data[0]));
                        await getMyWatchlist()
                        if (res.data[0].conditionAccepted) {
                            enqueueSnackbar("Logged in successfully", { variant: "success" })
                            navigate(`/dashboard?show=${(res.data[0]?.status === "FIRST_TIME" || res.data[0]?.status === "REGISTERED" || res.data[0]?.status === "KYC_APPROVED")}`, { state: res.data[0]?.status });
                        }
                        else {
                            setOtp(true);
                            setIsModalOpen(true);
                            onClose();
                        }
                    }
                    else {
                        if (res.data.message) {
                            enqueueSnackbar("OTP is incorrect", { variant: "error" })
                        }
                        else {
                            enqueueSnackbar("Something went wrong!", { variant: "error" })
                        }
                    }
                })
                .catch(err => enqueueSnackbar("Something went wrong!", { variant: "error" }))
        },
        onError: (error) => {
            console.log("Google Sign in error:", error)
            enqueueSnackbar("Something went wrong!", { variant: "error" })
        }
    })


    useEffect(() => {
        if (!otpForm) {
            setOtp("");
        }
    }, [otpForm])

    useEffect(() => {
        setNumber('')
        setInviteCode('')
        setOtp('')
        console.log("changing form")
    }, [signUp])

    const onClose = () => {
        setNumber("");
        // setOtp("");
        setOtpForm(false);
        handleClose();
        setTimeout(() => {
            setSignUp(false);
            setShowSignUp(false);
        }, 200)
    }

    const onCloseTerms = (value) => {
        setShowTerms(false);
        if (value) {
            enqueueSnackbar("Logged in successfully", { variant: "success" })
            navigate(`/dashboard?show=${(user?.status === "FIRST_TIME" || user?.status === "REGISTERED" || user?.status === "KYC_APPROVED")}`, { state: user?.status });
        }
        else {
            enqueueSnackbar("You cannot proceed without accepting buyer agreement.", { variant: "error" })

            loggout();
        }
    }

    return (
        <>
            <TermsConditions open={isModalOpen} handleClose={onCloseTerms} />
            {/* <ModalComponent open={open} handleClose={onClose} title={otpForm?"Enter OTP":signUp?"Signup":"Login"} > */}
            <Card className="card login-wrapper" style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: 'start' }}>
                <CardContent style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
                    <Stack direction={"column"} alignItems={"center"} style={{ textAlign: "center" }}>
                        <img src="/assets/images/logo.png" className="login-page-logo" />
                    </Stack>
                    <Stack direction={"column"} alignItems={"center"}>
                        <Typography style={{ textAlign: "left", paddingTop: "15px" }} variant="aboutHeading3">{otpForm ? "Enter OTP" : signUp ? "Signup" : "Login/Signup"}</Typography>
                    </Stack>
                    {
                        !otpForm ?
                            signUp ?
                                <SignupForm setSignUp={setSignUp} number={number} setNumber={setNumber} handleSendOtp={handleSendOtp} />
                                :
                                <LoginForm setSignUp={setSignUp} number={number} setNumber={setNumber} handleSendOtp={handleSendOtp} handleGoogleLogin={handleGoogleLogin} />
                            :
                            <OTPForm otp={otp} setOtp={setOtp} number={number} setOtpForm={setOtpForm} handleVerifyOtp={handleVerifyOtp} />
                    }

                </CardContent>
                <Footer></Footer>

            </Card>

            {/* </ModalComponent> */}
        </>
    )
}


const LoginForm = ({ number, setNumber, handleSendOtp, setSignUp, handleGoogleLogin }) => {
    return (
        <>
            <Stack spacing={1.5} mt={3}>
                <FormField label={"Enter Mobile No *"} inputProps={{
                    placeholder: "",
                    // type:"number",
                    value: number,
                    onChange: (e) => {
                        // console.log(RegExp('^[0-9]*$').test(e.target.value));
                        if (RegExp('^[0-9]*$').test(e.target.value) && e.target.value?.length < 11) {
                            setNumber(e.target.value)
                        }
                    },
                    InputProps: {
                        startAdornment: <InputAdornment position="start">
                            <Typography variant="body2" sx={{ color: "text.primary" }}>+91</Typography>
                        </InputAdornment>,
                        // endAdornment:<InputAdornment position="end">
                        //     <Button disabled={!(number?.length === 10)} variant="text">Send OTP</Button>
                        // </InputAdornment>
                    }
                }} />
                {/* <Stack direction={"row"} spacing={0.5}>
                    <Typography variant="body2">Have an invite code?</Typography>
                    <Typography onClick={()=>setSignUp(true)} variant="body2" sx={{color:"primary.main",cursor:"pointer"}}>Signup</Typography>
                </Stack> */}
            </Stack>
            <Button onClick={handleSendOtp} disabled={!(number?.length === 10)} variant="contained" sx={{ mt: 4 }} fullWidth >Send OTP</Button>
            <Button onClick={handleGoogleLogin} variant="contained" sx={{ mt: 4 }} fullWidth >
                Sign in with <GoogleIcon
                    color=""
                    sx={{ ml: 0.5, fontSize: "15px" }}
                    onClick={handleGoogleLogin}
                />
            </Button>
        </>
    )
}

const SignupForm = ({ number, setNumber, handleSendOtp, setSignUp }) => {

    return (
        <>
            <Stack spacing={1.5} mt={3}>
                <FormField label={"Enter Mobile No *"} inputProps={{
                    placeholder: "",
                    // type:"number",
                    value: number,
                    onChange: (e) => {
                        // console.log(RegExp('^[0-9]*$').test(e.target.value));
                        if (RegExp('^[0-9]*$').test(e.target.value) && e.target.value?.length < 11) {
                            setNumber(e.target.value)
                        }
                    },
                    InputProps: {
                        startAdornment: <InputAdornment position="start">
                            <Typography variant="body2" sx={{ color: "text.primary" }}>+91</Typography>
                        </InputAdornment>,
                        // endAdornment:<InputAdornment position="end">
                        //     <Button disabled={!(number?.length === 10)} variant="text">Send OTP</Button>
                        // </InputAdornment>
                    }
                }} />
            </Stack>
            <Button onClick={handleSendOtp} disabled={!(number?.length === 10)} variant="contained" sx={{ mt: 4 }} fullWidth >Send OTP</Button>
        </>
    )
}

const OTPForm = ({ otp, setOtp, number, setOtpForm, handleVerifyOtp }) => {

    return (
        <>
            <Stack direction={"column"} mt={3} justifyContent={"space-between"}>
                <Typography variant="body2" sx={{ fontSize: 12 }}>OTP Sent To {number}</Typography>
                <Typography onClick={() => setOtpForm(false)} variant="body2"  sx={{fontWeight:400,color: "primary.main", cursor: "pointer" }}>Change Number</Typography>
            </Stack>
            <Stack spacing={1.5} mt={3}>
                <FormField label={"Enter OTP *"} inputProps={{
                    placeholder: "_ _ _ _",
                    type: "number",
                    value: otp,
                    onChange: (e) => {
                        // console.log(RegExp('^[0-9]*$').test(e.target.value));
                        if (RegExp('^[0-9]*$').test(e.target.value) && e.target.value?.length < 5) {
                            setOtp(e.target.value)
                        }
                    },
                }} />
            </Stack>
            <Stack direction={"row"} mt={2} spacing={1}>
                <Typography variant="body2" >Haven't received OTP?</Typography>
                <Typography variant="body2" sx={{ color: "primary.main", cursor: "pointer" }}>Resend OTP</Typography>
            </Stack>
            <Button onClick={handleVerifyOtp} disabled={!(otp?.length === 4)} variant="contained" sx={{ mt: 4 }} fullWidth >Confirm and Next</Button>
        </>
    )
}

function Footer() {
    return (
        <footer style={{ color: "#333333", textAlign: "center" }}>
            <Typography variant="body2"> Copyright &copy;
                <a variant="body2" style={{ textDecoration: "none" }} href="https://realx.in"> RealX {new Date().getFullYear()}</a></Typography>      </footer>
    );
}
